<template>
  <div class="reset-password-app form-horizontal">
  <Form id="ResetPassword" key="ResetPassword" name="ResetPassword" :isEditing="true" :loadFormData="true" />
  </div>
</template>

<script>
import Form from '../../../../shared/components/Form.vue'
import EventBus from '@/app/shared/event-bus.js'

export default {
  name: 'ResetPassword',
  components: {
    Form
  },
  created () {
    EventBus.$on('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  methods: {
    formSavedSuccessfully (result) {
      window.location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
.reset-password-app {
  background-color: var(--background-bg-color);
}
* {
  margin: revert;
  box-sizing: unset;
}
.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.flex-container-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
